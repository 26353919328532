.NewEntryDialog {
  bottom: 0;
  right: 0;
}

.floating-fixed {
  z-index: 2;
  position: fixed;
}

.new-entry-container {
  display: flex;
  justify-content: flex-end;
  margin: 1em 1em;
}

.pulse-background {
  height: 40px;
  width: 40px;
  position: absolute;
  border-radius: 50%;
  animation: pulse 1.6s ease infinite;
  background: pink;
}

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
