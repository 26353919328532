.DailyEntry {
  width: 100%;
  /* for loading overlay */
  position: relative;
}

.carousel .carousel-status {
  right: 5px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 11px;
}

.DailyEntry span {
  line-height: 1.4em;
}

.date {
  font-weight: 700;
  margin-left: 0.5em;
}

.entry-content {
  margin: 0.5em;
}

.entry-text {
  white-space: pre-wrap;
}

.edit-entry-container {
  display: flex;
  align-content: flex-end;
  flex-direction: column;
}

.edit-buttons-container {
  justify-content: space-between;
  display: flex;
  align-content: flex-end;
  flex-direction: row;
}

* {
  box-sizing: border-box;
}

input.picture {
  display: none;
}

img {
  width: 100%;
}

.card-header + .card-content {
  padding-top: 0;
}
