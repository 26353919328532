.LogIn {
  text-align: center;
  width: 100%;
  background: linear-gradient(to left bottom, hsl(183, 100%, 85%) 0%, hsl(15, 100%, 85%) 100%);
  /* background: #866E4E; */
}

.firebaseui-idp-icon {
  height: 40px;
  width: 40px;
  margin: auto 8px;
}

img.logo {
  margin: 0 auto;
  height: 100px;
  width: 100px;
}

.firebaseui-idp-list {
  padding: unset;
}

.LogIn h1 {
  font-family: Source Sans Pro, Helvetica Neue, Arial, sans-serif;
}

.firebaseui-list-item {
  display: block;
  margin: 10px;
}

.firebaseui-list-item button {
  border: solid 1px gray;
  background: unset;
  width: 200px;
  text-align: left;
  border-radius: 5px;
  padding: 5px;
  display: inline-flex;
  align-items: center;
}

.firebaseui-idp-text-short {
  display: none;
}

.journal-cover {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
