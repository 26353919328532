.tile {
  float: left;
  position: relative;
  width: 30%;
  padding-bottom: 30%; /* = width for a 1:1 aspect ratio */
  margin: 1.66%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; /* you change this to "contain" if you don't want the images to be cropped */
}

.button-container {
  width: 100%;
  display: grid;
  justify-items: start;
  grid-template-columns: 1fr auto;
}

img.selectedImage {
  padding-bottom: 24px;
}
