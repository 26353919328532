.overlay-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /*dim the background*/
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  text-align: center;
  vertical-align: middle;
}
