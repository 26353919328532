.Page {
  text-align: center;
  width: 100%;
  background: linear-gradient(to left bottom, #b3fbff 0%, #ffc6b3 100%);
  display: flex;
  flex-direction: column;
}

.Page-logo {
  animation: Page-logo-spin infinite 20s linear;
  height: 80px;
}

.Page-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.Page-title {
  font-size: 1.5em;
}

.Page-intro {
  font-size: large;
}

@keyframes Page-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gap {
  flex: 1;
}

.last-section {
  flex-direction: column;
}

.credit {
  text-align: right;
  color: darkgray;
}

.helper-text {
  color: gray;
}
