.Controls {
  justify-content: space-between;
  display: flex;
  background: white;
  opacity: 0.6;
  min-height: 45px;
}

.date {
  align-self: center;
}

.flip-direction {
  transform: rotate(180deg);
}

button.MuiButtonBase-root-104 {
  height: 40px;
  width: 40px;
}
