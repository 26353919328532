body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: sans-serif;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

/* all dialog body are at least 400px */
.dialog-body.fullscreen {
  min-width: 400px;
}
